.rates-hero,
[class*='rates-hero--'] {
  padding-top: $spacing * 5.5 !important;

  @include mq(l) {
    padding-top: $spacing * 12.5 !important;
  }
}

.rates-hero__text {
  @extend %fw-light;

  margin-bottom: 0;

  @include mq(s) {
    max-width: col(8);
  }

  @include mq(xl) {
    max-width: col(6);
  }
}

.rates-hero__title {
  margin-top: 0;
  margin-bottom: $spacing * 1.25;
}

.rates-hero__excerpt {
  @extend %fw-light;

  margin-bottom: 0;
  font-family: $ff-alt;
}
