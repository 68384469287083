
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





































































































































































































































































































































































































































































@import '~vue-select/src/scss/vue-select';
@import '@shared/components/common/rates/index';

.rates__tarifs .pack-hero-price__link-outer {
  display: none;
}

.rates-mobile__table__label {
  @extend %fw-light;

  .rates-mobile__table__row__cell:first-child & {
    @extend %fw-normal;
  }
}

.rates-mobile__table__row__cell {
  &:first-child {
    text-align: left;
  }
}

.rates__tarifs {
  .pack-hero-price__item {
    .voo-app--business & {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

.rates-anchors {
  display: flex;
}

.rates-anchors__link {
  margin-right: 30px;
  color: $c-pink-medium;
}

.rates-hero__secondary {
  padding-top: 11rem !important;

  a {
    color: $c-pink-medium;
  }
}

.special-numbers-table {
  padding: 0;
}
