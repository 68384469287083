.rates-prices,
[class*='rates-prices--'] {
  overflow: auto;

  .generic-table__inner {
    min-width: 120rem;
  }
}

.rates-prices__header {
  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: $spacing * 0.5;
    fill: $c-pink-medium;
  }
}

.rates-prices__table,
[class*='rates-prices__table--'] {
  width: 100%;
}

.rates-prices__table__cell {
  padding: $spacing * 2.5;

  [class*='rates-prices__table--'][class*='--four-columns'] & {
    width: 25%;
  }
}

.rates-prices__label {
  color: $c-pink-medium;
}

.rates-prices__table__price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rates-prices__table__cell__labels {
  font-family: $ff-default;
  line-height: 1;
  text-align: left;

  strong {
    display: block;
  }

  &:first-child {
    margin-bottom: $spacing * 1.25;
  }
}

.rates-prices__footer {
  margin-top: $spacing;
  text-align: right;
}

.rates-prices__footer__link {
  display: inline-block;
  // margin-right: $spacing;
}

.rates-prices__footer__mention {
  @extend %fw-medium;
  margin-top: $spacing;
  color: $c-gray-light;
  font-family: $ff-default;
  font-size: 1.5rem;
  text-align: left;
}
