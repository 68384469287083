
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































$gap: 1rem;
$header-height: 10rem;
$offset-bottom: 3rem;

.generic-table__inner {
  position: relative;
}

.generic-table__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.generic-table__shadows {
  @include center-x;

  z-index: -1;
  top: 0;
  display: grid;
  grid-template-rows: minmax(#{$header-height}, auto) 1fr $offset-bottom;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: $gap;
  width: 100%;
  height: 100%;

  [class*='generic-table--'][class*='--four-columns'] & {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.generic-table__shadows__col,
[class*='generic-table__shadows__col--'] {
  display: grid;
  grid-template-rows: minmax(#{$header-height + $gap}, auto) 1fr (
      $offset-bottom + $gap
    );
  grid-gap: 0;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 2;
  overflow: hidden;
  box-shadow: 0 5px 40px rgba($black, 0.1);
  background: $white;
  border-radius: 1rem;

  &:nth-child(2) {
    grid-column: 2;
    box-shadow: -10px 10px 40px rgba($black, 0.1);
  }

  &:nth-child(3) {
    grid-column: 3;
  }

  &:nth-child(4) {
    grid-column: 4;
  }
}

.generic-table__content__table {
  overflow: hidden;
  padding-bottom: $offset-bottom + $gap;
  border-radius: 1rem 0 0 1rem;
}

.generic-table__content__table__cell {
  position: relative;
  z-index: 10;
}

.generic-table__shadows__table {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-right: $gap;
  background: transparent;
  box-shadow: -10px 10px 40px rgba($black, 0.1);
  border-radius: 1rem 0 0 1rem;

  [class*='generic-table--'][class*='--four-columns'] & {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

.generic-table__table__row {
  &:nth-child(odd) {
    background: rgba(200, 200, 200, 0.1);
  }
}

.generic-table__table__cell {
  @extend %fw-bold;

  width: 33.3%;
  padding: $spacing;
  font-family: $ff-alt;
  text-align: center;

  [class*='generic-table--'][class*='--four-columns'] & {
    width: 25%;
  }
}

.generic-table__content__header {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  margin-bottom: $gap;
}

.generic-table__content__header__block {
  width: calc(33.3% - #{$gap});
  min-height: $header-height;
  margin-left: $gap;
  border-radius: 1rem 1rem 0 0;

  &:first-child {
    width: 33.3%;
    margin-left: 0;
  }

  [class*='generic-table--'][class*='--four-columns'] & {
    width: calc(25% - #{$gap});

    &:first-child {
      width: 25%;
      margin-left: 0;
    }
  }
}
