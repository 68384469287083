.rates-options,
[class*='rates-options--'] {
  background: $c-blue-dark;
}

.rates-options__title {
  @extend %fw-light;

  margin-top: 0;
  color: $white;

  @include mq(s) {
    max-width: col(8);
    margin-bottom: $spacing * 2.5;
  }

  @include mq(xl) {
    max-width: col(6);
  }
}

.rates-options__dropdowns {
  @include mq(s) {
    display: flex;
    margin: 0 -#{$spacing};
  }
}

.rates-options__dropdowns__item {
  max-width: 43rem;

  &:not(:last-child) {
    margin-bottom: $spacing * 2;
  }

  @include mq(s) {
    width: calc(50% - #{$spacing * 2});
    margin: 0 $spacing;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.rates-options__dropdowns__item__label {
  display: block;
  margin-bottom: $spacing * 0.25;
  color: $white;
}

.rates-options__dropdowns__item__select {
  svg {
    fill: $c-pink-medium;
  }

  .vs__dropdown-toggle {
    padding: $spacing;
    background: $white;
  }

  .vs__search,
  .vs__actions,
  .vs__selected,
  .vs__selected-options {
    margin: 0;
    border: 0;
    padding: 0;

    @include mq($until: m) {
      font-size: 2rem;
    }
  }

  .vs__actions {
    line-height: 1;
  }
  .vs__clear {
    margin-bottom: 0.3rem;
  }

  &.vs--disabled {
    .vs__search {
      color: $white;
      background-color: transparent;
    }

    .vs__dropdown-toggle {
      background: $c-blue-dark;
      border: 2px solid hsla(0, 0%, 87.8%, 0.6);
    }

    svg {
      display: none;
    }
  }

  .vs__search {
    @extend %fw-bold;
    color: $c-blue-dark;
    font-family: $ff-alt;
  }

  .vs__selected {
    @extend %fw-bold;

    border-radius: 0;
    @include mq($until: m) {
      font-size: 2rem;
    }
  }

  @include mq($until: m) {
    font-size: 2rem;
  }

  @include mq(l) {
    .vs__dropdown-toggle {
      padding: $spacing * 1.5;
    }
  }
}
